import { create } from 'ipfs-http-client'
const ipfs = create('https://ipfs.infura.io:5001')

// 统一错误返回
const failResult = (message) => ({ success: false, message })
// 统一成功返回
const successResult = (result) => ({ success: true, result })

export const uploadFile = async (file) => {
  if (!file) return failResult('no-data')
  try {
    const result = await ipfs.add(file)
    const resp = `https://ipfs.infura.io/ipfs/${result.path}`
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}
